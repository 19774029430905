import {CHANGE_LOCALE} from "../actions";

export default (state, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.code
      };
    default:
      return state;
  }
};
