import React from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import Header from "../Header/Header";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import Networks from "../Networks/Networks";
import {localizeContent, localizeTitle} from "./utils/localization";
import "./App.css";

class App extends React.Component {
    configureLocale = () => {
        const {locale} = this.props;
        document.title = localizeTitle(this.props.content.title, locale);
    };

    componentDidMount() {
        this.configureLocale();
    }

    componentDidUpdate(prevProps) {
        if (this.props.locale !== prevProps.locale) {
            this.configureLocale();
        }
    }

    render() {
        const {content, locale} = this.props;
        const {locales} = content;
        const {
            images,
            networks,
            strings,
            title,
        } = localizeContent(content, locale);

        return (
            <div className={classnames(locale, "page")}>
                <Nav locales={locales} />
                <div className="flexible1" />
                <Header
                    logoImageSource={images.logo}
                    nameImageSource={images.name}
                    title={title}
                />
                <div className="flexible1" />
                <main className="content">
                    <Networks content={networks} title={strings.networks} />
                </main>
                <div className="flexible3" />
                <Footer
                    copyrightAuthor={strings.copyrightAuthor}
                    copyrightNotice={strings.copyrightNotice}
                    emailImageSource={images.email}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    locale: state.locale
});

export default connect(mapStateToProps)(App);
