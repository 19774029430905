import React from "react";
import Link from "../../Link/Link";
import "./Network.css";

export default class Network extends React.PureComponent {
  render() {
    const {content} = this.props;

    return (
      <li>
        <Link external id={content.name} href={content.url}>
          <img src={content.logo} alt={content.name} />
          <span>{content.name}</span>
        </Link>
      </li>
    );
  }
}
