import React from "react";
import "./Footer.css";

export default class Footer extends React.PureComponent {
    render() {
        const {copyrightAuthor, copyrightNotice, emailImageSource} = this.props;
        const copyrightYear = new Date().getFullYear();

        return (
            <footer>
                <div className="contacts">
                    <img src={emailImageSource} alt="" />
                </div>
                <div className="copyrights">
                    <span className="line">&copy; <span id="copyrightYears">{copyrightYear}</span> {copyrightAuthor}</span>
                    <span className="line">{copyrightNotice}</span>
                </div>
            </footer>
        );
    }
}
