import React from "react";
import Network from "./Network/Network";
import "./Networks.css";

export default class Networks extends React.PureComponent {
  render() {
    const {content, title} = this.props;
    const subcomponents = content.map((network, index) => (
      <Network content={network} key={index} />
    ));

    return (
      <section>
        <div className="sectionTitle">{title}</div>
        <ul>{subcomponents}</ul>
      </section>
    );
  }
}
