import React from "react";
import ReactDOM from "react-dom";
import {applyMiddleware, createStore} from "redux";
import {Provider} from "react-redux";
import reducer from './reducers';
import {initializeState, stateMiddleware} from './middleware/state';
import App from "./components/App/App";
import content from "./json/content";
import "./styles/common.css";

const supportedLocales = content.locales.map(locale => locale.code);
const store = createStore(
  reducer,
  initializeState(supportedLocales),
  applyMiddleware(stateMiddleware())
);
const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <App content={content} />
  </Provider>,
  rootElement
);
