import React from "react";
import Locale from "./Locale/Locale";
import "./Nav.css";

export default class Nav extends React.PureComponent {
    renderLocaleComponent = (locale) => (
        <Locale content={locale} key={locale.code} />
    );

    render() {
        const {locales} = this.props;

        return (
            <div className="nav">
                {locales.map(this.renderLocaleComponent)}
            </div>
        );
    }
}
