import React from "react";
import {ANALYTICS_CLASS} from "../../constants/analytics";
import {normalized} from "../../utils/String";

export default class Link extends React.PureComponent {
    render() {
        const {
            children,
            external,
            href,
            id,
            trackable,
        } = this.props;

        const normalizedId = normalized(id || href);
        const className = trackable === false ? undefined : ANALYTICS_CLASS.LINK;

        return (
            <a
                id={normalizedId}
                className={className}
                href={href}
                {...external ? {target: "_blank"} : {}}
            >
                {children}
            </a>
        );
    }
}
