import React from "react";
import Link from "../Link/Link";
import "./Header.css";

export default class Header extends React.PureComponent {
    render() {
        const {logoImageSource, nameImageSource, title} = this.props;

        return (
            <header>
                <div className="logo">
                    <Link id="home" href="/">
                        <img src={logoImageSource} alt="" />
                    </Link>
                </div>
                <h1>
                    <Link id="home" href="/">
                        <img src={nameImageSource} alt={title} />
                    </Link>
                </h1>
            </header>
        );
    }
}
