import queryString from "query-string";
import {getLocaleFromCookies, setLocaleToCookies} from "../utils/cookies";

const LOCALE_QUERY_KEY = "locale";

const loadDefaults = (supportedLocales) => ({
    locale: supportedLocales[0]
});

const loadFromCookies = (supportedLocales) => {
    let state = {};

    const locale = getLocaleFromCookies();
    if (supportedLocales.includes(locale)) {
        state.locale = locale;
    }

    return state;
};

const loadFromQuery = (supportedLocales) => {
    let state = {};

    const query = queryString.parse(window.location.search);

    const locale = query[LOCALE_QUERY_KEY];
    if (supportedLocales.includes(locale)) {
        state.locale = locale;
    }

    return state;
};

export const initializeState = (supportedLocales) => {
    const state = {
        ...loadDefaults(supportedLocales),
        ...loadFromCookies(supportedLocales),
        ...loadFromQuery(supportedLocales)
    }

    stateChanged({}, state);

    return state;
};

export const stateMiddleware = () => store => next => action => {
    const previousState = store.getState();
    next(action);
    const currentState = store.getState();

    if (previousState !== currentState) stateChanged(previousState, currentState);
};

const stateChanged = (previousState, currentState) => {
    if (previousState.locale !== currentState.locale) {
        setLocaleToCookies(currentState.locale);
    }
};
