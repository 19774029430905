import React from "react";
import {connect} from "react-redux";
import {changeLocale} from "../../../actions";
import "./Locale.css";

class Locale extends React.PureComponent {
    onChange = () => {
        const {dispatch, content} = this.props;
        dispatch(changeLocale(content.code));
        this.button.blur();
    };

    render() {
        const {content} = this.props;

        return (
            <button
                className="locale-button"
                ref={button => this.button = button}
                onClick={this.onChange}
            >
                <img src={content.logo} alt={content.localizedName} />
            </button>
        );
    }
}

export default connect()(Locale);
