import Cookies from "js-cookie";

const LOCALE_COOKIE_KEY = "locale";

export const getLocaleFromCookies = () => (
  Cookies.get(LOCALE_COOKIE_KEY)
);

export const setLocaleToCookies = (locale) => {
  Cookies.set(
    LOCALE_COOKIE_KEY,
    locale,
    {expires: 365, path: "/"}
  );
};
