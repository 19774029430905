import {localized} from "../../../utils/String";

export const localizeContent = (content, locale) => {
    const {images, networks, strings, title} = content;

    const localizedTitle = localizeTitle(title, locale);

    const localizedImages = Object.fromEntries(
        Object.entries(images).map(([key, image]) => [
            key,
            localized(image, locale)
        ])
    );

    const localizedNetworks = networks.map(network => ({
        ...network,
        name: localized(network.name, locale)
    }));

    const localizedStrings = Object.keys(strings).reduce((reduced, key) => ({
        ...reduced,
        [key]: localized(strings[key], locale)
    }), {});

    return {
        images: localizedImages,
        networks: localizedNetworks,
        strings: localizedStrings,
        title: localizedTitle
    };
};

export const localizeTitle = (title, locale) => (
    localized(title, locale)
);
